<template>
  <div class="network-container">
    <div class="network-activate">
      {{ $t('network_activate_title')}}
    </div>
    <div v-if="device">
      <p class="tracker-name">Tracker: {{ device.name }}</p>
      <div v-if="sigfoxStatus==='disabled'">
        <p>{{ $t('network_deactivated_msg')}}</p>
        <div class="network-activate-button">
          <button class="btn btn-lg btn-primary btn-block "
                  @click="requestActivate"
                  type="submit" :disabled="submitted">{{$t('activate')}}
          </button>
        </div>
      </div>
      <div v-else>
        <p>{{ $t('network_activated_msg')}}</p>
    </div>
      </div>
  </div>
</template>

<script>


import { mapActions, mapGetters } from "vuex";

export default {
  name: 'NetworkActivate',
  components: {
  },
  data() {
    return {
      device: null,
      serial: null,
      submitted: false
    }
  },

  methods: {
    ...mapActions('devices', ['fetchDevices', 'setSelectedDevice']),
    ...mapActions('devices', ['removeSelectedDevice']),
    ...mapActions('devices', ['removeDevices']),
    ...mapActions('devices', ['enableNetwork']),
    ...mapActions('users', ['setToken']),

    async requestActivate() {
      this.submitted = true
      console.log("requestActivate");
      const res = await this.enableNetwork(this.device)
      console.log("process with result:", res)
      await this.loadDevice();
    },
    async loadDevice() {
      await this.fetchDevices();
      this.device = this.getDevices.filter((device) => {
        return device.serial === this.serial;
      })[0];

      console.log("Device: ", this.device);
    }

  },
  computed: {
    ...mapGetters('devices', ['getDevices', 'getSelectedDevice']),
    sigfoxStatus(){
      const network_extra = this.device.tracker_status.network_extra;
      if (network_extra && network_extra.sigfox_status) {
        return network_extra.sigfox_status;
      }
      return undefined
    }
  },

  created() {

  },

  async mounted() {
    console.log("NetworkActivate mounted");
    let parameters = this.$route.query;
    console.log(parameters);
    if (parameters['token']) {
      let token = parameters['token'];
      this.setToken(token);
    }

    await this.removeDevices();

    if (parameters["serial"]) {
      this.serial = parameters["serial"];
      await this.loadDevice()
    }
  },
}
</script>

<style scoped>
.bg-lightgrey {
  background-color: rgb(247, 241, 241, 0.3);
}
.tracker-name{
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  padding-bottom: 20px;
}
.network-container{
  padding: 1.5em;
  height: 100% !important;
}
.network-activate {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 28px;
  text-align: left;
  padding-bottom: 30px;
}
.network-activate-button {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 90%;
  flex-direction: column;
  padding-bottom: 20px;
}
</style>